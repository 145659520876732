import React from 'react'
import {Container} from 'reactstrap'
import Link from "./link";

export default ({title}) => (
  <div className="kbus-bg-grey">
    <Container className="d-flex flex-row justify-content-center py-3">
      <Link to={"/privacidad"} className="small text-uppercase">
        privacidad
      </Link>
      <span className="small px-2">|</span>
      <Link to={"/aviso-legal"} className="small text-uppercase">
        aviso legal
      </Link>
      <span className="small px-2">|</span>
      <Link to={"/politica-de-cookies"} className="small text-uppercase">
        política de cookies
      </Link>
    </Container>
  </div>
)
