import React from 'react'
import {Container, Row, Col} from 'reactstrap'
import {FaPhone, FaWhatsapp} from 'react-icons/fa';
import Link from './link';
import styled from "styled-components"

import kbusLogoBlanco from '../assets/images/logo/kbus_logo_blanco.png'

const Footer = styled.footer`
  padding: 3rem 0;
  ul {
    -webkit-padding-start: 0;
    padding: 0;
    & > li {
      list-style: none;
      a, a:hover {
        color: inherit;
      }
    }
    
    li + li {
      padding-top: 0.6rem;
    }
  }
`

let FooterLink = ({to, children, className}) => (
  <li className={className}>
    <Link to={to}>
      {children}
    </Link>
  </li>
)

export default ({menuCopies}) => (
  <Footer className="kbus-bg-grey small">
    <Container fluid className="px-xl-5">
      <Row>
        <Col xs="12" md="3" lg="2">
          <img src={kbusLogoBlanco} alt="kBus"/>
        </Col>
        <Col xs="12" md="6" lg="8" className="pt-5 pt-md-4 px-lg-5 text-uppercase">
          <Row className="mx-xl-5">
            <Col xs="12" md="4" lg="2">
              <ul>
                <li className="no-text-cursor">{menuCopies.lineasYHorarios}</li>
                <FooterLink className="pl-3 pl-md-0" to={"/proximo-bus"}>{menuCopies.proximoBus}</FooterLink>
                <FooterLink className="pl-3 pl-md-0" to={"/calcula-tu-ruta"}>{menuCopies.calculaTuRuta}</FooterLink>
                <FooterLink className="pl-3 pl-md-0" to={"/parada-mas-cercana"}>{menuCopies.paradaMasCercana}</FooterLink>
                <FooterLink className="pl-3 pl-md-0" to={"/lineas-y-horarios"}>{menuCopies.lineasYHorarios}</FooterLink>
              </ul>
            </Col>
            <Col xs="12" md="4" lg="2">
              <ul>
                <FooterLink to={"/tarifas"}>{menuCopies.tarifas}</FooterLink>
              </ul>
            </Col>
            <Col xs="12" md="4" lg="2">
              <ul>
                <li className="no-text-cursor">{menuCopies.sobreKbus}</li>
                <FooterLink className="pl-3 pl-md-0" to={"/quien-somos"}>{menuCopies.quienSomos}</FooterLink>
                <FooterLink className="pl-3 pl-md-0" to={"/accesibilidad"}>{menuCopies.accesibilidad}</FooterLink>
              </ul>
            </Col>
            <Col xs="12" md="4" lg="2">
              <ul>
                <li className="no-text-cursor">{menuCopies.atencionAlUsuario}</li>
                <FooterLink className="pl-3 pl-md-0" to={"/canales-de-contacto"}>{menuCopies.canalesDeContacto}</FooterLink>
                <FooterLink className="pl-3 pl-md-0" to={"/quejas-y-sugerencias"}>{menuCopies.quejasYSugerencias}</FooterLink>
                <FooterLink className="pl-3 pl-md-0" to={"/objetos-perdidos"}>{menuCopies.objetosPerdidos}</FooterLink>
                <FooterLink className="pl-3 pl-md-0" to={"/pago-de-infracciones"}>{menuCopies.pagoDeInfracciones}</FooterLink>
                <FooterLink className="pl-3 pl-md-0" to={"/normativa"}>{menuCopies.normativa}</FooterLink>
              </ul>
            </Col>
            <Col xs="12" md="4" lg="2">
              <ul>
                <FooterLink to={"/contacto"}>{menuCopies.contacto}</FooterLink>
              </ul>
            </Col>
            <Col xs="12" md="4" lg="2" className={'d-block d-md-none'}>
              <ul>
                <FooterLink to={"/descarga-aplicacion"}>{menuCopies.descargaAplicacion}</FooterLink>
              </ul>
            </Col>
          </Row>
        </Col>

        <Col xs="12" md="3" lg="2" className="pt-4">
          <ul>
            <FooterLink className="text-md-right text-nowrap" to={"mailto: kbus@kbus.eus"}>kbus@kbus.eus</FooterLink>
            <FooterLink className="text-md-right text-nowrap" to={"tel: +34610561124"}><FaPhone size={18} className="mr-2"/>+34&nbsp;689&nbsp;826&nbsp;051</FooterLink>
          </ul>
        </Col>

      </Row>
    </Container>
  </Footer>
)
