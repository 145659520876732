import React from 'react';
import Link from './link'
import {FaBus, FaMapPin, FaRoute} from "react-icons/fa";

const FloatShortcut = () => {
  return (
    <div className="d-none d-md-flex flex-column justify-content-center align-items-center kbus-float-shortcut-container">
      <div className={"w-100 pb-3 px-2 text-center"}>
        <Link to="/proximo-bus" className="" activeClassName=""><FaBus size={"32"} style={{color: "#BB2218"}}/></Link>
      </div>
      <div className={"divider"}></div>
      <div className={"w-100 py-3 px-2 text-center"}>
        <Link to="/calcula-tu-ruta" className="" activeClassName=""><FaRoute size={"32"} style={{color: "#BB2218"}}/></Link>
      </div>
      <div className={"divider"}></div>
      <div className={"w-100 pt-3 px-2 text-center"}>
        <Link to="/parada-mas-cercana" className="" activeClassName=""><FaMapPin size={"32"} style={{color: "#BB2218"}}/></Link>
      </div>
    </div>
  )
}

export default FloatShortcut;
