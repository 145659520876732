import React from 'react';
import Link from './link'
import {FaBus, FaMapPin, FaRoute} from "react-icons/fa";
import Sticky from 'react-sticky-el';

const FloatShortcutMobile = () => {
  return (
    <Sticky stickyClassName={"kbus-sticky"}>
      <div className="d-flex d-md-none flex-row justify-content-center align-items-center kbus-float-shortcut-container-mobile ml-3">
        <div className={"w-100 py-2 px-2 text-center"}>
          <Link to="/proximo-bus" className="" activeClassName=""><FaBus size={"20"} style={{color: "#BB2218"}}/></Link>
        </div>
        <div className={"w-100 py-2 px-2 text-center"}>
          <Link to="/calcula-tu-ruta" className="" activeClassName=""><FaRoute size={"20"} style={{color: "#BB2218"}}/></Link>
        </div>
        <div className={"w-100 py-2 px-2 text-center"}>
          <Link to="/parada-mas-cercana" className="" activeClassName=""><FaMapPin size={"20"} style={{color: "#BB2218"}}/></Link>
        </div>
      </div>
    </Sticky>
  )
}

export default FloatShortcutMobile;
