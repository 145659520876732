import React from 'react'
import {Col, Row} from "reactstrap";

export default ({title}) => (
  <Row>
    <Col xs={{size: 12, offset:0}} md={{size:8, offset:2}} lg={{size:6, offset:3}}>
      <h4 className="text-center font-weight-bold border-bottom pb-3">{title}</h4>
    </Col>
  </Row>
)
