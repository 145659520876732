import React from 'react'
import { Container } from 'reactstrap'

export default ({changeLang, activeLang}) => {
  return (
    <div className="kbus-bg-red kbus-upper-banner">
      <Container>
        <div className="d-none d-md-flex justify-content-end">
          <span role="button" tabIndex={0} className={`${activeLang === 'eus' ? 'font-weight-bold': ''} no-link-decoration`} onClick={() => {changeLang('eus')}} onKeyDown={() => {changeLang('eus')}}>EUS</span>
          <span className="px-2">|</span>
          <span role="button" tabIndex={0} className={`${activeLang === 'esp' ? 'font-weight-bold': ''} no-link-decoration`} onClick={() => {changeLang('esp')}} onKeyDown={() => {changeLang('esp')}}>ESP</span>
        </div>
      </Container>
    </div>
  )
}
